/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
function t(t, r) {
  return new Proxy({}, {
    get: (e, i, s) => (...e) => {
      let s, o;
      const a = e[e.length - 1];
      n(a) && (s = a.signal, o = a.transferList, e.pop());
      return t.apply(r ? `${r}.${i.toString()}` : i.toString(), e, {
        transferList: o,
        signal: s
      });
    }
  });
}
function n(t) {
  return "object" == typeof t && !Array.isArray(t) && null != t && ("signal" in t || "transferList" in t || 0 === Object.keys(t).length);
}
export { t as createInvokeProxy };